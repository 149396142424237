<template>
  <div class="px-2 container_wrapper">
    <section class="selfcare-title d-flex">
      <div>
        <a class="btn btn-link btn-sm" style="color: #000" @click="goBack()">
          <svg
            fill="var(--text-color)"
            class="material-design-icon__svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
          >
            <path
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            ></path>
          </svg>
        </a>
      </div>
    </section>
    <div class="px-2">
      <h4 class="text-dark pt-2">{{ $t('frequentlyAskedQuestions') }}</h4>

      <h6 class="text-dark pt-2">{{ $t("makingDeposit") }}</h6>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="promo-content text-dark collapse show"
          data-toggle="collapse"
          data-target="#1"
          aria-expanded="false"
          aria-controls="1"
          @click="toggleCollapseFirstSection"
        >
          <strong>{{ $t("howToDeposit") }}</strong>
          <span
            style="float: right"
            v-html="isCollapsed ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse show"
          id="1"
        >
          {{ $t("howToDepositAns") }}
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#4"
          aria-expanded="false"
          aria-controls="4"
          @click="toggleCollapse('4')"
        >
          <strong>{{ $t("useDiffPhoneNumbers") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['4'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="4"
        >
          {{ $t("useDiffPhoneNumbersAns") }}
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#5"
          aria-expanded="false"
          aria-controls="5"
          @click="toggleCollapse('5')"
        >
          <strong>{{ $t("cantSeeMoney") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['5'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="5"
        >
          {{ $t("cantSeeMoneyAns") }}
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#6"
          aria-expanded="false"
          aria-controls="6"
          @click="toggleCollapse('6')"
        >
          <strong>{{ $t("minDeposit") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['6'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="6"
        >
          {{ $t("minDepositAns") }}
        </div>
      </section>

      <h6 class="text-dark pt-2">{{ $t("verifyingAccount") }}</h6>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#7"
          aria-expanded="false"
          aria-controls="7"
          @click="toggleCollapse('7')"
        >
          <strong>{{ $t("verifyingAccountNeccessary") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['7'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="7"
        >
          {{ $t("verifyingAccountNeccessaryAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#8"
          aria-expanded="false"
          aria-controls="8"
          @click="toggleCollapse('8')"
        >
          <strong>{{ $t("howVerificationWork") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['8'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="8"
        >
          {{ $t("howVerificationWorkAns") }}
          <ol>
            <li>{{ $t("nationalID") }}</li>
            <li>{{ $t("votersCard") }}</li>
            <li>{{ $t("passport") }}</li>
            <li>{{ $t("driversLicense") }}</li>
          </ol>
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#9"
          aria-expanded="false"
          aria-controls="9"
          @click="toggleCollapse('9')"
        >
          <strong>{{ $t("account_verification.question") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['9'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="9"
        >
          <p>{{ $t("account_verification.ans") }}</p>
          <p>{{ $t("account_verification.answer1") }}</p>
          <p>{{ $t("account_verification.answer2") }}</p>
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#10"
          aria-expanded="false"
          aria-controls="10"
          @click="toggleCollapse('10')"
        >
          <strong>{{ $t("howToUploadDocument") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['10'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="10"
        >
          <p>{{ $t("howToUploadDocumentAns") }}</p>
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#11"
          aria-expanded="false"
          aria-controls="11"
          @click="toggleCollapse('11')"
        >
          <strong>{{ $t("neccesaryDocument") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['11'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="11"
        >
          {{ $t("neccesaryDocumentAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#12"
          aria-expanded="false"
          aria-controls="12"
          @click="toggleCollapse('12')"
        >
          <strong>{{ $t("timeToVerifyDocument") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['12'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="12"
        >
          <p>{{ $t("timeToVerifyDocumentAns") }}</p>
        </div>
      </section>

      <h6 class="text-dark pt-2">{{ $t("withrawingCash") }}</h6>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#13"
          aria-expanded="false"
          aria-controls="13"
          @click="toggleCollapse('13')"
        >
          <strong>{{ $t("withrawingCashAccount") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['13'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="13"
        >
          <p>{{ $t("withrawingCashAccountAns") }}</p>
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#14"
          aria-expanded="false"
          aria-controls="14"
          @click="toggleCollapse('14')"
        >
          <strong>{{ $t("withrawingCashPhone") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['14'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="14"
        >
          <p>{{ $t("withrawingCashPhoneAns") }}</p>
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#15"
          aria-expanded="false"
          aria-controls="15"
          @click="toggleCollapse('15')"
        >
          <strong>{{ $t("withrawingCashWallet") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['15'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="15"
        >
          {{ $t("withrawingCashWalletAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#16"
          aria-expanded="false"
          aria-controls="16"
          @click="toggleCollapse('16')"
        >
          <strong>{{ $t("withdrawMin") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['16'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="16"
        >
          {{ $t("withdrawMinAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#17"
          aria-expanded="false"
          aria-controls="17"
          @click="toggleCollapse('17')"
        >
          <strong>{{ $t("cashWithdrawNotWorking") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['17'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="17"
        >
          {{ $t("withdrawalNotWorking.answer1") }}
          <ul>
            <li>{{ $t("withdrawalNotWorking.answer2") }}</li>
            <li>{{ $t("withdrawalNotWorking.answer3") }}</li>
            <li>{{ $t("withdrawalNotWorking.answer4") }}</li>
          </ul>
          <p>{{ $t("withdrawalNotWorking.answer5") }}</p>
        </div>
      </section>

      <h6 class="text-dark pt-2">{{ $t("bettingOnChopBet") }}</h6>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#18"
          aria-expanded="false"
          aria-controls="18"
          @click="toggleCollapse('18')"
        >
          <strong>{{ $t("howToPlaceBet") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['18'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="18"
        >
          {{ $t("howToPlaceBetAns.answer1") }}
          <ul>
            <li>{{ $t("howToPlaceBetAns.answer2") }}</li>
            <li>{{ $t("howToPlaceBetAns.answer3") }}</li>
            <li>{{ $t("howToPlaceBetAns.answer4") }}</li>
            <li>{{ $t("howToPlaceBetAns.answer5") }}</li>
          </ul>
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#19"
          aria-expanded="false"
          aria-controls="19"
          @click="toggleCollapse('19')"
        >
          <strong>{{ $t("amountToPlaceBet") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['19'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="19"
        >
          {{ $t("amountToPlaceBetAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#20"
          aria-expanded="false"
          aria-controls="20"
          @click="toggleCollapse('20')"
        >
          <strong>{{ $t("lostComplaint") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['20'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="20"
        >
          {{ $t("lostComplaintAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#21"
          aria-expanded="false"
          aria-controls="21"
          @click="toggleCollapse('21')"
        >
          <strong>{{ $t("history") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['21'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="21"
        >
          {{ $t("historyAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#22"
          aria-expanded="false"
          aria-controls="22"
          @click="toggleCollapse('22')"
        >
          <strong>{{ $t("cashOut") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['22'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="22"
        >
          {{ $t("cashOutAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#23"
          aria-expanded="false"
          aria-controls="23"
          @click="toggleCollapse('23')"
        >
          <strong>{{ $t("betslipNotUpdating") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['23'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="23"
        >
          {{ $t("betslipNotUpdatingAns") }}
        </div>
      </section>

      <h6 class="text-dark pt-2">{{ $t("recoveringData") }}</h6>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#24"
          aria-expanded="false"
          aria-controls="24"
          @click="toggleCollapse('24')"
        >
          <strong>{{ $t("restorePassword") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['24'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="24"
        >
          {{ $t("restorePasswordAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#25"
          aria-expanded="false"
          aria-controls="25"
          @click="toggleCollapse('25')"
        >
          <strong>{{ $t("confirmationCode") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['25'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="25"
        >
          {{ $t("confirmationCodeAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#26"
          aria-expanded="false"
          aria-controls="26"
          @click="toggleCollapse('26')"
        >
          <strong>{{ $t("changeAccPassword") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['26'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="26"
        >
          {{ $t("changeAccPasswordAns") }}
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="
            background-color: var(--slide-fill);
            padding: 4px;
            border-radius: 7px;
            padding: 15px;
          "
          class="text-dark"
          data-toggle="collapse"
          data-target="#27"
          aria-expanded="false"
          aria-controls="27"
          @click="toggleCollapse('27')"
        >
          <strong>{{ $t("attemptRestorePassword") }}</strong>
          <span
            style="float: right"
            v-html="collapsedSections['27'] ? arrowUpIcon : arrowDownIcon"
          ></span>
        </div>
        <div
          style="
            background-color: var(--slide-fill);
            padding: 15px;
            border-top: dotted 1px black;
          "
          class="promo-content text-dark collapse"
          id="27"
        >
          {{ $t("attemptRestorePasswordAns") }}
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq",
  data() {
    const totalSections = 27;
    const collapsedSections = {};

    for (let i = 1; i <= totalSections; i++){
      collapsedSections[`${i}`] = false;
    }
    return {
      collapsedSections,
      isCollapsed: true,
      arrowDownIcon: `
        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.5999 7.45703L11.1666 12.8904C10.5249 13.532 9.4749 13.532 8.83324 12.8904L3.3999 7.45703"
            stroke="var(--sub-text-color)"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      `,
      arrowUpIcon: `
        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.3999 12.543L8.83324 7.10966C9.4749 6.468 10.5249 6.468 11.1666 7.10966L16.5999 12.543"
            stroke="var(--sub-text-color)"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      `,
    };
  },
  components: {},
  methods: {
 
    toggleCollapseFirstSection() {
    this.isCollapsed = !this.isCollapsed
  },
    toggleCollapse(sectionId) {
    if (sectionId) {
      this.$set(this.collapsedSections, sectionId, !this.collapsedSections[sectionId]);
    }
  },
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "Faq");
    this.reloadProfile();
  },
};
</script>

<style src="./index.css" scoped></style>
